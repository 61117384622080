import { render, staticRenderFns } from "./emmanuel_ajala_refKbox.vue?vue&type=template&id=4ed48ff4&scoped=true&"
import script from "./emmanuel_ajala_refKbox.vue?vue&type=script&lang=js&"
export * from "./emmanuel_ajala_refKbox.vue?vue&type=script&lang=js&"
import style0 from "./emmanuel_ajala_refKbox.vue?vue&type=style&index=0&id=4ed48ff4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed48ff4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VSnackbar})
